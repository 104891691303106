<template>
    <div class="fieldmgmt_table">
         <!-- LIST OF FIELDS -->
            <b-table
            responsive="sm"
            :items="items"
            show-empty
            empty-text="No matching records found"
            >  
                   
            <template #cell(FieldName)="data">
                {{data.item.FieldName.fieldname}}
            </template> 
            <template #cell(action)="data">
                <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
                    <b-img src='@/assets/images/erflog/Edit.png' :disabled='isLoading' @click="editDataBind(data)" class="action_icon"/>
                    <b-img src='@/assets/images/erflog/Delete.png' :disabled='isLoading' @click="deleteField(data)" class="action_icon"/>
                </div>
                <div v-else>
                    --
                </div>
            </template> 
            </b-table>
            <p v-show="items.length == 0" style="color: red;font-size: small;font-style: italic;text-align: center;">No Data Found</p>
            <!-- NEW DOC ADD  -->
            <validation-observer
            ref="fieldMOduleValidator"          
            >      
            <div class="mx-0 mb-4">       
                <b-row v-for='(data, index) in newField' :key="index" class="mb-1">  
                <b-col md="6"> 
                    <validation-provider
                    #default="{errors}"
                    name="fieldName"
                    rules="required"
                    >  
                    <b-form-input 
                    v-model='data.fieldName' 
                    name='fieldName' 
                    placeholder='Enter Text'
                    :disabled='isLoading'  
                    :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small> 
                    </validation-provider>         
                </b-col> 
                <b-col md="4"/>
                <b-col md="2" style="text-align:right">
                <!-- EDIT SOME DOC's -->
                <b-button v-show="data.isEditable"  variant="outline-success" :disabled='isLoading' @click="editFieldData(data,index)" class="action_btn">Edit</b-button>
                <b-button v-show="data.isEditable==false" variant="outline-success" :disabled='isLoading' @click="saveFields(data)" class="action_btn">Save</b-button>
                <b-button  variant="outline-danger" :disabled='isLoading' @click="cancelAction(data,index)" class="action_btn">Cancel</b-button>
                </b-col>  
                </b-row>
                <b-row>
                    <b-col>              
                <label><a href='#' @click="addnewFieldInp()">Add New Field</a></label>
                    </b-col>
                </b-row>
            </div>
            </validation-observer>
            <!-- <div v-if='isLoading' class="loading"></div> -->
            <div v-if="isLoading" class="loading loading-custom">         
                <div class="effect-1 effects"></div>
                <div class="effect-2 effects"></div>
                <div class="effect-3 effects"></div>     
            </div>
    </div>
</template>
<script>
import {  extend ,ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules'
import { BTable,BRow,BCol,BFormInput,BDropdown,BDropdownItem,BButton,BImg, BFormFile, BTab, BTabs,  } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//FIREBASE IMPORT
import firebase from "firebase"
import { dbCollections } from '@/utils/firebaseCollection.js';
import * as inserQuery from '@/utils/FirebaseQueries/insertQueries/insertQueries.js'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
//POPUP
import Ripple from 'vue-ripple-directive'
extend('required',required)
export default {
   components: {
    BFormFile,
    BTable,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BButton,
    BImg, 
    ValidationProvider,
    ValidationObserver, 
    BTab,
    BTabs        
  },
  directives: {
    Ripple,
  },
  data(){
       return{
        // STORE DATA   
        newField:[],
        items:[],
        //   LOAD DATA 
        isLoading:false,          
       }
   },
   watch: {
    $route: function () {        
        this.init();          
    }
   },
   created(){
       this.init();
   },
   methods:{
       saveFields(data,index){
           var self = this;
            if(self.$refs.fieldMOduleValidator){
                    self.$refs.fieldMOduleValidator.validate().then(success => {
                        if(success){
                            self.isLoading = true;
                            let obj = {
                                'fieldName':data.fieldName,
                                'createdAt' : firebase.firestore.FieldValue.serverTimestamp(),
                                'updatedAt' : firebase.firestore.FieldValue.serverTimestamp(),
                                'isDeleted':false
                            }                            
                            inserQuery.addDataToRootCollection(dbCollections.FIELDS,obj,resp=>{                               
                                let updateID = {
                                    'id':resp.id
                                }
                                updateQuery.updateRootCollectionDataByDocId(dbCollections.FIELDS,resp.id,updateID,updateRes=>{
                                    self.isLoading = false;
                                    console.log(updateRes)
                                    self.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: 'Document Add Successfully',
                                            icon: 'CoffeeIcon',
                                            variant: 'success',                               
                                        },
                                    })
                                    self.cancelAction(data,index)
                                })
                            })    
                        }else{
                            self.isLoading = false;
                        }
                    })
            }    
       },
       addnewFieldInp(){
            var self = this;
            if(self.newField.length <= 0){
                self.newField.push({
                    'fieldName':'',
                    'isEditable':false
                })
            }else{
               if(self.$refs.fieldMOduleValidator){
                    self.$refs.fieldMOduleValidator.validate().then(success => {
                        if(success){
                            self.newField.push({
                                'fieldName':'',
                                'isEditable':false
                            })
                        }
                    })
               } 
            } 
       },
       init(){
           var self = this;          
           getQuery.getDataWithSpecificWhereCondition(dbCollections.FIELDS,'isDeleted','==',false,res=>{
               if(res.length > 0){         
                   self.items = [];
                   res.forEach(element => {
                       self.items.push({
                           'FieldName':{
                            "fieldname":element.fieldName,
                           "id":element.id
                            },
                            'action':false
                        })
                   });
               }else{
                   self.items = [];
               }
           }) 
       },
       deleteField(data){
            let id = data.item.FieldName.id;
            if(this.isLoading==false && data.item.action == false){
                // this.isLoading = true;
                this.$swal({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText:'No',
                        customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                }).then(result => {
                    if (result.value) {
                        this.isLoading = true;  
                        let updateObj = {
                            isDeleted:true
                        }
                        updateQuery.updateRootCollectionDataByDocId(dbCollections.FIELDS,id,updateObj,updateRes=>{
                            console.log(updateRes,"?")
                            this.isLoading = false;
                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Your file has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })                           
                        }) 
                    }
                })
            }  
       },
       editDataBind(data){
           var self = this;          
           if(this.isLoading==false && data.item.action == false){
            data.item.action = true;
            self.newField.push({
                'fieldName':data.item.FieldName.fieldname,
                'isEditable':data.item.action,
                'id':data.item.FieldName.id
            })
           }
       },
       editFieldData(data,index){
            var self = this; 
            if(self.$refs.fieldMOduleValidator){
                    self.$refs.fieldMOduleValidator.validate().then(success => {
                        if(success){
                            self.isLoading = true;
                            let updateObj = {                                
                                'fieldName':data.fieldName                              
                            }
                            updateQuery.updateRootCollectionDataByDocId(dbCollections.FIELDS,data.id,updateObj,updateRes=>{
                                console.log(updateRes)
                                self.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: 'Document Updated Successfully',
                                            icon: 'CoffeeIcon',
                                            variant: 'success',                               
                                        },
                                })
                                self.isLoading = false;
                                self.cancelAction(data,index)
                            })  
                        }else{
                            self.isLoading = false;                                                        
                        }
                    })
            }
       },
       cancelAction(data,index){
           var self = this;
           data.isEditable = false;           
           let findIndex = self.items.findIndex(element=>{
               return element.FieldName.id == data.id
           })
           if(findIndex !== -1){
              self.items[findIndex].action = false 
           }
           self.newField.splice(index,1)
       }
   },
}
</script>
<style>


@-webkit-keyframes rotate {
  /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>