<template>
    <div class="document_main_wrapper">
         <!-- button -->
        <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="modalShow = !modalShow"
        style="display:none"
        v-model="modalShow"
        ref='openCreateDocModal'
        >
            Launch Modal
        </b-button>

        <b-card>
        <!-- SEARCH INPUT -->
        <b-row>
                <b-col
                md="6"
                sm="6"
                class="d-flex align-items-center justify-content-start "
                >
                <b-form-group    
                    class="mb-2"
                >     
                    <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                    />
                </b-form-group>
                </b-col>
         <!-- SEARCH END -->
         <!-- PAGINATION START -->      
                <b-col
                md="6"
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"       
                >
                <v-select
                    v-model="perPage"
                    style="width: 90px"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                />
                </b-col>
        </b-row>
        <!-- PAGINATION END -->
        <!-- LIST OF DOCS -->
        <b-table
        striped
        hover
        responsive
        show-empty
        empty-text="No matching records found"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="columns"
        :filter="filter"
        v-model="companyDocTable"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        class="documentable_management_wrapper position-relative p-0_table"
        >
        <template #cell(no)="data">  
           <span>{{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}</span>
        </template>  
        <template #cell(upload_demo_file)="data">  
            <b-col class="">
                 <b-img  style='cursor:pointer; pointer-events: auto;' v-show="data.item.collectionFile.length > 0" src='@/assets/images/erflog/Download.png' @click="downloadAttachments(data)" :aria-disabled="isLoading" class="action_icon"/>
            </b-col>    
            <!-- <b-col>{{Number(data.index + 1)}}</b-col>           -->
        </template>  
        <template #cell(action)="data">
            <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
                <b-img src='@/assets/images/erflog/Edit.png' :aria-disabled="isLoading" @click="editDoc(data)" class="action_icon"/>
                <b-img src='@/assets/images/erflog/Delete.png' :aria-disabled="isLoading" @click.prevent="selectedRemoveData=data" v-b-modal.delete-field class="action_icon"/>
            </div>
            <div v-else>
                --
            </div>
        </template> 
        </b-table>

         <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                    </b-col>    
                    <!-- PAGINATION FOOTER -->
                    <b-col
                        cols="12"
                        md="12"
                        class="d-flex align-items-center justify-content-end"
                    >
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalPagination"
                        :per-page="perPage"
                        right
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                    </b-pagination>
                    </b-col>
                </b-row>
        </div>
        <p v-show="items.length == 0" style="color: red;font-size: small;font-style: italic;text-align: center;">No Data Found</p>      
        </b-card>
        <!-- MODEL OF CREATE DOCUMENT -->
        <b-modal
            v-model="modalShow"
            :title="ModelTitle"
            ok-title="Save"
            centered
            cancel-title='Cancel'
            id="update_document"
            @cancel.prevent='closeCreateDocModule' 
            @ok.prevent='saveDoc()'     
        >
 
            <validation-observer
            ref="formValidator"          
            >      
            <div class="mb-1">  
                <!-- <div v-if="isLoading" class="loading loading-custom">  -->
                <!-- </div>      -->
                <div v-if="isLoading==true" class="loading loading-custom">         
                    <div class="effect-1 effects"></div>
                    <div class="effect-2 effects"></div>
                    <div class="effect-3 effects"></div>     
                </div>
                <!-- <b-spinner variant="primary" v-if="isLoading" large class="pageLoadSpinners"/> -->
                <b-row>  
                    <b-col md="6"> 
                        <b-form-group label="Document Name">
                        <validation-provider
                        #default="{errors}"
                        name="document Name"
                        rules="required"
                        >  
                        <b-form-input 
                        v-model='docModule.docName' 
                        name='docName' 
                        :disabled='isLoading==true'
                        placeholder='Enter Text'  
                        :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small> 
                        </validation-provider> 
                        </b-form-group>        
                    </b-col>  
                    <b-col md="6">
                        <b-form-group label="File Type">
                            <validation-provider
                            #default="{errors}"
                            name="Document Type"
                            rules="required"
                            > 
                            <div class="demo-inline-spacing">                           
                            <b-form-radio
                            name="single"
                            id='radio1'
                             plain
                            value="single" 
                            :state="errors.length > 0 ? false:null" 
                            v-model="docModule.docType"     
                            @change='manageFiles'  
                            class="mt-0"                    
                            >
                            Single
                            </b-form-radio>
                            <b-form-radio
                            name="double"
                            id='radio2'
                             plain
                            value="double"
                            :state="errors.length > 0 ? false:null" 
                            v-model="docModule.docType"
                            @change='manageFiles'
                             class="mt-0" 
                            >
                            Double
                            </b-form-radio>

                            </div>
                             <small class="text-danger">{{ errors[0] }}</small> 
                            </validation-provider>
                        </b-form-group>                      
                    </b-col>                
                </b-row>  
                <b-row>
                    <b-col md="4">
                        <b-form-group label="Upload Demo File" class="modal_filetype" title="please select first filetype" >
                            <validation-provider
                            #default="{errors}"
                            name="Document files"
                            > 
                              <b-form-file multiple :state="errors.length > 0 ? false:null"  @change="handleFileChange($event)" :disabled="docModule.docType==''" placeholder="Upload Demo File"  v-model='docModule.docFile' drop-placeholder="Drop file here..."/> 
                              <!-- <small class="text-danger" v-for="(file,index) in docModule.docFile" :key="index">{{ errors[0] }}</small> -->
                              <small class="text-danger">{{ errors[0] }}</small>
                              <small class="text-danger" v-if="docModule.errorMessage.selectFirst.length > 0">{{ docModule.errorMessage.selectFirst }}</small>
                            </validation-provider>
                        </b-form-group>
                        <span v-for="(img,index) in docModule.docFile" :key="index">
                            {{img.name}}{{index==docModule.docFile.length-1?'.':','}}
                        </span>
                    </b-col>
                </b-row>
            </div>
            </validation-observer> 
        </b-modal>

         <!-- DELETE MODEL -->
        <b-modal
        id="delete-field"
        cancel-variant="outline-secondary"
        ok-title="Yes"
        cancel-title="No"
        centered
        title=""
        :no-close-on-backdrop="true"
        @hidden="selectedRemoveData=''"
        v-if="selectedRemoveData!=''"
        @ok="deleteField(selectedRemoveData)"
      >
      <div class="create_account_img">
      <img src="@/assets/images/erflog/DeletePopup.png" alt="">
    </div>
        <div style="text-align: center;" v-if="selectedRemoveData!=''">
            <label class="s_modal_title">Delete Field</label>
            <p>Are you sure you want to delete these document?</p>
        </div>
       </b-modal>
        <div v-if="isSpinner" class="loading loading-custom">         
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>     
       </div>
    </div>    
</template>
<script>
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {  extend ,ValidationProvider, ValidationObserver } from 'vee-validate';
 import { required } from 'vee-validate/dist/rules'
import { BSpinner,BPagination,BFormSelect,BTable,BRow,BCol, BCard,BFormInput,BDropdown,BDropdownItem,BButton,BImg, BFormFile, BTab, BTabs, BFormGroup, BFormRadio, BFormRadioGroup} from 'bootstrap-vue'
import VSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//FIREBASE IMPORT
import firebase from "firebase"
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import * as inserQuery from '@/utils/FirebaseQueries/insertQueries/insertQueries.js'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js'
import * as uploadFile from '@/utils/FirebaseQueries/storageQuery/storageQueries.js'
import { dbCollections,settingsCollectionDocs } from '@/utils/firebaseCollection.js';
import Ripple from 'vue-ripple-directive'
import fieldMgmt from './fieldMgmt.vue';
extend('required',required)
export default {
  components: {
    BPagination,
    BFormFile,
    BTable,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BButton,
    BImg,   
    VSelect,
    ValidationProvider,
    ValidationObserver, 
    BTab,
    BTabs,
    fieldMgmt,    
    BFormGroup,
    BFormRadio,
    BCard,
    BFormRadioGroup,
    BSpinner,
    BFormSelect        
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [], 
      searchQuery:'',
      newDocModule:[],
      arry:[
          "Student",
          "Admin",
          "Employee"
      ],
      category:'', 
      categoryArry:[],
      storageFolder: 'documents',
      filesSelected:{},
      images:[],
      isLoading:false,
      newField:[],
      columns:[       
        // {
        //   label: 'No.',
        //   key: 'no', 
        // },
        {
          label: 'Document Name',
          key: 'document_name.name',
          sortable:true
        },       
        {
          label: 'File Type',
          key: 'File_Type', 
          sortable:true
        },
        {
          label: 'Sample Document',
          key: 'upload_demo_file',
          sortable:true
        },
        {
          label: 'Action',
          key: 'action', 
        },
      ],
      modalShow:false,
      docModule:{
          id:'',
          docName:'',
          docType:'single',
          docFile:[],
          errorMessage:{
              selectFirst:''
          }
      },
      ModelTitle:"Add Document",
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],   
      selectedRemoveData:{},
      isSpinner:false,
      companyDocTable:[],
      totalPagination:0     
    }
  },
   watch: {
    $route: function () {        
        this.init();          
    }
   },
  created(){      
      this.init() 
      this.$root.$on('createNewDocument',()=>{
        //   alert("DOON")
        this.ModelTitle = "Add Document";
        this.docModule={
          id:'',  
          docName:'',
          docType:'single',
          docFile:[],
          errorMessage:{
              selectFirst:''
          }
        }  
        this.modalShow=true; 
        // this.$refs.openCreateDocModal.click();
        this.$root.$emit('stopSpinner')
      })
  },
  methods:{
      init(){     
          var self = this;
          getQuery.getDataFromRootCollectionWithDocID(dbCollections.SETTING,settingsCollectionDocs.DOCUMENTCATEGORY,response=>{             
              self.categoryArry = [];
              if(response){
                  if(response.category !== undefined && response.category.length > 0){               
                      response.category.forEach(element => {
                          if(element){
                              self.categoryArry.push(element)
                          }
                      });
                  }
              }
          })          
          getQuery.getDataFromRootCollection(dbCollections.DOCUMENTS,res=>{ 
              if(res && res.length > 0){                  
                  self.items = [];
                  res.forEach(values=>{
                      let allImgName = [];
                      let fileStr = '';
                      if(values && values.isDeleted == false){  
                        if(values.docFile.length > 0){                          
                                values.docFile.forEach(file=>{
                                    allImgName.push(file.imageName)
                                })
                        }   
                        fileStr = JSON.stringify(allImgName).replace('[','')
                        fileStr = fileStr.replace(']','').toString(); 
                        fileStr = fileStr.split('"').join('')  
                        fileStr = fileStr.split(',').join('/')  
                        self.items.push({                             
                               document_name: {'name':values.documentName,id:values.id},
                               File_Type:values.fileType,                               
                               upload_demo_file:values.docFile,
                               collectionFile:values.docFile,
                               action:''
                          })
                          self.totalPagination = self.items.length;                         
                      }
                  })
              }
          })
      },
      handleFileChange(evt) {
        this.docModule.errorMessage.selectFirst = ''
        let files = evt.target.files;
        var validImages = true
        if(files && files.length > 0){
            this.filesSelected = files;     
            if(this.docModule.docType !== '' && this.docModule.docType == 'double'){
                if(files.length !== 2){
                   this.docModule.errorMessage.selectFirst = `Please select two file again`; 
                   this.docModule.docFile = [];
                   validImages = false
                   return;
                }else{
                    this.docModule.errorMessage.selectFirst = ''
                }
            }
            if(this.docModule.docType !== '' && this.docModule.docType == 'single'){
                if(files.length !== 1){
                   this.docModule.errorMessage.selectFirst = `Please select one file again`;
                   this.docModule.docFile = [];
                   validImages = false
                   return;
                }else{
                    this.docModule.errorMessage.selectFirst = ''
                }
            } 
            // EXTENSION VALIDATION
            files.forEach(val=>{
                if(val.type !== 'image/jpeg' && val.type !== 'image/jpg' && val.type !== ".jpeg" && val.type !== ".jpg" && val.type !== ".pdf" && val.type !== "application/pdf" && val.type !== ".png" && val.type !== "image/png" && val.type !== ".doc" && val.type == "application/doc"){
                   this.docModule.errorMessage.selectFirst = `File allow only jpeg ,jpg, png or pdf, doc[upload Again]`; 
                   this.docModule.docFile = [];
                   validImages = false
                }
            })
            console.log("Docs",this.docModule.docFile,files)
        }
      },
      addDocStorage(data,cbb){
        // let tempArry = []  
        if(data){
        uploadFile.uploadImageFile(this.storageFolder,data.name,data,res=>{                    
            // tempArry.push(res)                    
            // if(storageRes.length == Number(newDocModule.docFile.length)){ 
                cbb(res)
            // }
        }) 
        }   
      },
      uploadDocumentFile(newDocModule,cb){
        let storageRes = []  
        var self = this;         
        if(newDocModule.docFile.length !== 0){ 
            let fileArry = newDocModule.docFile;
            let count = 0;
            const uploadFileFunction = (row) => {
               if(count >= fileArry.length){  
                   cb(storageRes) 
                   return;                
               }
                self.addDocStorage(row,(saveRes)=>{
                    console.log(saveRes,"?savedRess?")                    
                    storageRes.push(saveRes)
                    count += 1;
                    uploadFileFunction(fileArry[count]);
                });              
            }
            uploadFileFunction(fileArry[count]);              
        }else{
            cb(storageRes)
        }
      },    
      setSubCategoryArry(objectData,index){
          var self = this;
          let subArry = [];  
          let subCategoryName = [];    
          self.categoryArry.forEach(ele=>{              
              if(ele.name == objectData){
                  subArry = ele.subCategory;                
              }
          })
          setTimeout(()=>{            
            if(subArry.length > 0){            
              subArry.forEach((values)=>{
                  if(values){   
                      subCategoryName.push(values.name)   
                  }
              })
             self.newDocModule[index].subCategory = subCategoryName;
            } 
          },1000)          
      },    
      removeDoc(Data){
        if(this.isLoading==false && Data.item.action !== true){
           this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText:'No',
                customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                this.isLoading = true;
                 let removeData = this.items[Data.index];             
                let updateObj = {
                    isDeleted : true
                }
                updateQuery.updateRootCollectionDataByDocId(dbCollections.DOCUMENTS,removeData.document_name.id,updateObj,res=>{
                    console.log(res,"?")                  
                    this.$swal({
                        icon: 'success',
                        title: 'Deleted!',
                        text: 'Your file has been deleted.',
                        customClass: {
                        confirmButton: 'btn btn-success',
                        },
                    })
                    this.isLoading = false; 
                })        
                }
            })
        }        
      },
      removeDocImg(data,cb){
          var self = this;
          if(data.length > 0){
              if(data.length > 0){
                let count = 0;
                const deleteFileFunction = (row) => {
                if(count > data.length){  
                     cb(row) 
                    return                
                }
                    self.removeDocFile(row,(saveRes)=>{
                        console.log(saveRes,":-)")
                        count += 1;
                        deleteFileFunction(data[count]);
                    });              
                }
                deleteFileFunction(data[count]);                          
              }
          }
      },
      removeDocFile(data,deleCb){
        var self = this;     
        if(data && data.imageName !== undefined){           
            uploadFile.removeImage(self.storageFolder,data.imageName,resp=>{
                console.log(resp);    
                deleCb(resp)                
            })
        }else{
            deleCb(false)
        }
      },
      closeCreateDocModule(){
          this.modalShow = false
      },
      manageFiles(){
          var self = this;
          if(this.docModule.docFile.length > 0){
             let files = this.docModule.docFile;
            if(this.docModule.docType !== '' && this.docModule.docType == 'double'){
                if(files.length !== 2){
                   this.docModule.errorMessage.selectFirst = `Please select two file again`; 
                   this.docModule.docFile = [];
                }else{
                    this.docModule.errorMessage.selectFirst = ''
                }
            }
            if(this.docModule.docType !== '' && this.docModule.docType == 'single'){
                if(files.length !== 1){
                   this.docModule.errorMessage.selectFirst = `Please select one file again`;
                   this.docModule.docFile = [];
                }else{
                    this.docModule.errorMessage.selectFirst = ''
                }
            }
          }
          //   EDIT DOCUMENT
          if(this.ModelTitle == "Update Document"){ 
            let removeDocFiles = [];
            let itemInd =self.items.findIndex(itemval=>{
                return itemval.document_name.id == this.docModule.id
            })
            if(itemInd > -1){
                removeDocFiles = self.items[itemInd].collectionFile;           
                if(this.docModule.docType !== '' && this.docModule.docType == 'double'){
                    if(removeDocFiles.length !== 2){
                        this.docModule.errorMessage.selectFirst = `Please select two file again`; 
                        this.docModule.docFile = [];
                    }else{
                        this.docModule.errorMessage.selectFirst = ''
                    }
                }
                if(this.docModule.docType !== '' && this.docModule.docType == 'single'){
                    if(removeDocFiles.length !== 1){
                        this.docModule.errorMessage.selectFirst = `Please select one file again`;
                        this.docModule.docFile = [];
                    }else{
                        this.docModule.errorMessage.selectFirst = ''
                    }
                }                      
            }     
          }
      },  
      saveDoc(){
          var self = this;
          if(this.ModelTitle == "Add Document"){
            if(self.$refs.formValidator){
                self.$refs.formValidator.validate().then(success => {
                    if (success && this.docModule.errorMessage.selectFirst == ''){                    
                        self.isLoading = true;                              
                        self.uploadDocumentFile(this.docModule,resp=>{
                            if(resp){
                                console.log(resp,"JYOTI")
                                let obj = {
                                        createdAt : firebase.firestore.FieldValue.serverTimestamp(),
                                        updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
                                        isDeleted: false,                                    
                                        documentName: this.docModule.docName,
                                        fileType:this.docModule.docType,
                                        docFile: resp
                                } 
                                inserQuery.addDataToRootCollection(dbCollections.DOCUMENTS,obj,res=>{  
                                    self.isLoading = false;
                                    let updateId={
                                        'id':res.id
                                    }
                                    updateQuery.updateRootCollectionDataByDocId(dbCollections.DOCUMENTS,res.id,updateId,res=>{
                                        console.log(res)
                                        self.isLoading = false;
                                        self.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: 'Document Add Successfully',
                                            icon: 'CoffeeIcon',
                                            variant: 'success',                               
                                        },
                                        })                                                       
                                        self.closeCreateDocModule() 
                                            // self.init()
                                        })                                
                                })                           
                                self.isLoading = false;
                            }
                        })
                    }               
                })
            }
           }else{
            this.updateDoc()
           } 
      },
      //   BIND DOCUMENT DATA
      editDoc(data){
        this.ModelTitle = "Update Document";
        this.docModule.docName=data.item.document_name.name;
        this.docModule.docType=data.item.File_Type,
        this.docModule.docFile=[];
        this.docModule.id=data.item.document_name.id;
        this.docModule.errorMessage.selectFirst='';     
        this.modalShow=true;   
        // this.$refs.openCreateDocModal.click();       
      },
      //   SAVE UPDATED FIELDS
     updateDoc(){
          var self = this;
           self.isLoading = true;   
           if(this.docModule.docFile.length > 0){  
            let removeDocFiles = [];
            let itemInd =self.items.findIndex(itemval=>{
                return itemval.document_name.id == this.docModule.id
            })
            if(itemInd > -1){
                removeDocFiles = self.items[itemInd].collectionFile;               
                self.removeDocImg(removeDocFiles,res=>{
                    console.log(res,"DELETE SUCCESSFULLY")
                })                          
            }                 
           setTimeout(()=>{
               self.uploadDocumentFile(this.docModule,resp=>{
                if(resp){
                    let obj = {                       
                        updatedAt : firebase.firestore.FieldValue.serverTimestamp(),                                                           
                        documentName: this.docModule.docName,
                        fileType:this.docModule.docType,
                        docFile: resp
                    }  
                    updateQuery.updateRootCollectionDataByDocId(dbCollections.DOCUMENTS,this.docModule.id,obj,res=>{                                                
                    self.isLoading = false;  
                    self.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Document Update Successfully',
                        icon: 'CoffeeIcon',
                        variant: 'success',                               
                    },
                    })                                                       
                    self.closeCreateDocModule() 
                    })                           
                    self.isLoading = false;
                }
            }) 
           },1000) 
            }else{               
                let obj = {                       
                    updatedAt : firebase.firestore.FieldValue.serverTimestamp(),                                                           
                    documentName: this.docModule.docName,                                      
                }  
                updateQuery.updateRootCollectionDataByDocId(dbCollections.DOCUMENTS,this.docModule.id,obj,res=>{                                                                                            
                self.isLoading = false;                       
                self.isLoading = false;
                self.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Document Update Successfully',
                    icon: 'CoffeeIcon',
                    variant: 'success',                               
                },
                })                                                       
                self.closeCreateDocModule() 
                })   
                self.isLoading = false;
           } 
     },
      downloadDocument(data,cb){
      let url = data.imageUrl;
      let name = data.imageName;    
      this.$axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download',name);
            document.body.appendChild(fileLink);
            fileLink.click();
            cb(true) 
        })
        .catch(error=>{
             this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: error,
                        icon: 'CoffeeIcon',
                        variant: 'danger',                               
                    },
            })         
        })
      },
      downloadAttachments(data){     
          if(data.item.upload_demo_file.length > 0){
            let count = 0;
            const uploadFileFunction = (row) => {
               if(count >= data.item.upload_demo_file.length){        
                   return           
               }
                this.downloadDocument(row,(saveRes)=>{
                    count += 1;
                    // if(data.item.upload_demo_file.length < count){
                        uploadFileFunction(data.item.upload_demo_file[count]);
                    // }
                });              
            }
            uploadFileFunction(data.item.upload_demo_file[count]);
            }
      },
      onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      if(this.filter !== ""){
        this.totalPagination = this.companyDocTable.length;                         
      }else{
        this.totalPagination = this.items.length;                         
      }
      },
      deleteField(data){
         if(this.isLoading==false && data.item.action !== true){   
            this.isLoading = true;
            let removeData = this.items[data.index];             
            let updateObj = {
                isDeleted : true
            }
            updateQuery.updateRootCollectionDataByDocId(dbCollections.DOCUMENTS,removeData.document_name.id,updateObj,res=>{
            console.log(res,"?")                  
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  title: 'Document Remove Successfully',
                  icon: 'CoffeeIcon',
                  variant: 'success',                               
              },
            }) 
            this.isLoading = false; 
            })        
         }
      },
  },
  computed:{
       dataMeta(){
            const localItemsCount = this.companyDocTable ? this.companyDocTable.length : 0
            if(this.filter !== "" && this.filter !== null){
            // console.log("search",this.totalRows)           
            return {
                from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                to: this.perPage * (this.currentPage - 1) + localItemsCount,
                of: this.companyDocTable.length,
            }
            }else{
            // console.log("not search")
                  return {
                    from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                    to: this.perPage * (this.currentPage - 1) + localItemsCount,
                    of: this.items.length,
                 }
            }          
      }
  }
}
</script>
<style lang="scss">
.per-page-selector {
  width: 170px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>

@-webkit-keyframes rotate {
  /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>
<style>
.position-relative.table-responsive thead tr th{
  white-space: nowrap !important;
}
/* .position-relative.table-responsive tbody tr td{
  white-space: nowrap !important;
} */
.s_modal_title{
    width: 100%;
    font-weight: 600;
    font-size: 20px;
}
.documentable_management_wrapper.position-relative.p-0_table.table-responsive {
    padding: 0px!important;
}
</style>
